<template>
  <div>
    <!-- style="margin-top:20px;" -->
    <el-row>
      <el-form :inline="true" :model="form" label-width="40px">
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item label="学号">
          <el-input v-model="form.name" placeholder="请输入学号"></el-input>
        </el-form-item>

        <el-form-item label="区县">
          <el-select v-model="form.county" placeholder="请选择区县">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校">
          <el-select v-model="form.school" placeholder="请选择学校">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试">
          <el-input v-model="form.name" placeholder="请输入考试"></el-input>
        </el-form-item>

        <el-form-item label="科目">
          <el-select v-model="form.subject" placeholder="请选择科目">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="form.subject" placeholder="请选择状态">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" icon="el-icon-refresh-right"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
        :header-cell-style="{ 'text-align': 'center', background: '#DFE6EC' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="nickName"
          label="姓名"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="examId"
          label="考号"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="county"
          label="区县"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="school"
          label="学校"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="examName"
          label="考试"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="subject"
          label="考试科目"
          show-overflow-tooltip
          width="150"
        ></el-table-column>
        <el-table-column
          prop="score"
          label="成绩"
          show-overflow-tooltip
          width="100"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="申请理由"
          show-overflow-tooltip
          width="150"
        ></el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          show-overflow-tooltip
          width="100"
        ></el-table-column>
        <el-table-column
          label="操作"
          min-width="130px"
          show-overflow-tooltip
          width="300"
        >
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon
              @click="check(scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon
              @click="edit(scope.row)"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 查看弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="550px"
      :close-on-click-modal="false"
    >
      <span class="diaTitle">化学模拟考0105选择题02</span>
      <span class="diaTime">2022/01/05 11:40 - 2022/01/05 18:00</span>
      <span slot="footer" class="dialog-footer">
        <el-button v-throttle type="primary" size="medium">阅卷</el-button>
        <el-button v-throttle type="primary" size="medium">通过</el-button>
        <el-button v-throttle type="danger" size="medium">驳回</el-button>
      </span>
      <div class="pageBlock">
        <img class="blockPic" src="@/assets/blockPic.png" alt />
        <img class="centerPic" src="@/assets/centerPic.png" alt />
        <div class="content">
          <div>
            <img src="@/assets/content1.png" alt />
            <span class="name">姓名&nbsp;:</span>
            <span class="details">白白</span>
          </div>
          <div>
            <img src="@/assets/content2.png" alt />
            <span class="name">考号&nbsp;:</span>
            <span class="details">123456789012</span>
          </div>
          <div>
            <img src="@/assets/content3.png" alt />
            <span class="name">学校&nbsp;:</span>
            <span class="details">长鹏中学</span>
          </div>
          <div>
            <img src="@/assets/content4.png" alt />
            <span class="name">状态&nbsp;:</span>
            <span class="details">正常</span>
          </div>
          <div>
            <img src="@/assets/content5.png" alt />
            <span class="name">申请理由&nbsp;:</span>
            <span class="details" style="margin-left: 55px">评分有误</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-row style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'examObjection',
  data() {
    return {
      form: {
        name: '',
      },
      list: [
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '待审批',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '已驳回',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '待阅卷',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '处理完成',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '待审批',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '待审批',
        },
        {
          nickName: '苏明成',
          examId: 2020010101,
          county: '番禺区',
          school: '长鹏中学',
          examName: '测量小灯泡电功率实验',
          subject: '物理',
          score: 98,
          account: '没头绪',
          state: '待审批',
        },
      ],
      //分页
      page: {
        pageIndex: 1,
        pagSize: 10,
        totalSize: 0,
      },
      //弹窗
      dialogVisible: false,
    }
  },
  mounted() {},
  methods: {
    handleSelectionChange() {},
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.loading = true
      this.page.pageIndex = val
      this.init()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      this.loading = true
      this.page.pagSize = val
      this.init()
    },

    //查看按钮-弹出弹窗
    check() {
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
$black: #000000;
$fontBlack: #303133;
::v-deep .el-dialog {
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #707070;
}
.diaTitle {
  display: block;
  margin-top: 30px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: $black;
}
.diaTime {
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: $black;
  margin-top: 30px;
}
.dialog-footer {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  .el-button {
    margin: 0px 20px 0px 20px;
  }
}
.pageBlock {
  .blockPic {
    position: relative;
    margin-top: 60px;
    margin-left: 13%;
    transform: scale(1.1);
  }
  .centerPic {
    position: absolute;
    left: 47%;
    top: 37%;
    transform: scale(1.1);
  }
  .content {
    position: absolute;
    width: 300px;
    top: 52%;
    left: 24%;
    vertical-align: middle;
    div {
      height: 37px;
      img {
        width: 15px;
        vertical-align: -1px;
      }
    }
    .name {
      margin-left: 10px;
      font-size: 15px;
      font-weight: bold;
      color: $fontBlack;
    }
    .details {
      margin-left: 85px;
      color: $fontBlack;
      font-size: 15px;
    }
  }
}
</style>
